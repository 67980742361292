import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Card } from 'react-bootstrap';
import Logo from "../../assets/images/logoGiga.png";
import Image from "../../assets/images/qrcode.svg";

import './styles.css';

// ...

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        // Verificar se o token existe no localStorage
        const token = localStorage.getItem('token');

        // Se o token existir, redirecionar para a página home
        if (token) {
            navigate("/home");
        }
    }, [navigate]);

    const validateForm = () => {
        let isValid = true;

        // Validar campo de usuário
        if (!username.trim()) {
            setUsernameError('Digite seu nome de usuário');
            isValid = false;
        } else {
            setUsernameError('');
        }

        // Validar campo de senha
        if (!password.trim()) {
            setPasswordError('Digite sua senha');
            isValid = false;
        } else {
            setPasswordError('');
        }

        return isValid;
    };

    const handleUsernameBlur = () => {
        if (!username.trim()) {
            setUsernameError('Campo obrigatório!');
        }
    };

    const handlePasswordBlur = () => {
        if (!password.trim()) {
            setPasswordError('Campo obrigatório!');
        }
    };

    const handleLogin = async () => {
        try {
            if (validateForm()) {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { username, password });
                const receivedToken = response.data.token;

                // Armazenar o token no localStorage
                localStorage.setItem('token', receivedToken);

                // Decodificar o token para obter as informações
                // const decodedToken = jwt.decode(receivedToken);

                // Exemplo de como acessar dados do token
                // const usernameFromToken = decodedToken.username;
                // const emailFromToken = decodedToken.email;

                // console.log('Login successful! Token:', receivedToken);
                // console.log('Username from token:', usernameFromToken);
                // console.log('Nome from token:', emailFromToken);

                // Redirecionar para a página home
                navigate('/home');
            }
        } catch (error) {
            // Capturar erro 401 (Unauthorized) e exibir mensagem amigável
            if (error.response && error.response.status === 401) {
                console.error('Login failed:', error.message);
                // Atualizar mensagem de erro para o usuário
                setUsernameError('Credenciais inválidas. Por favor, verifique seu nome de usuário e senha.');
            } else {
                // Lidar com outros erros de forma padrão
                console.error('Login failed:', error.message);
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Evitar o comportamento padrão do formulário (recarregar a página)
        handleLogin();
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-3 mx-auto text-center">
                        <div className='card-login'></div>
                        <Card>
                            <Card.Body>
                                <div className='login'>
                                    <div className='logo'>
                                        <img src={Logo} alt="" />
                                    </div>
                                    <hr />
                                    <div className='wifi-home'>
                                        <p>Monte seu cardápio eletrônico rapidamente com nosso <strong>GigaMenu Creator</strong></p>
                                    </div>
                                    <div className='wifiz'>
                                        <img src={Image} alt="" />
                                        <p><strong>Parceria Gigabyte/Cliente</strong> </p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-group mb-1">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Usuário"
                                                aria-label="Username"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                onBlur={handleUsernameBlur} />
                                        </div>
                                        {usernameError && <span className="error-message">{usernameError}</span>}
                                        <div className="input-group mb-1">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fa fa-lock" aria-hidden="true"></i>
                                            </span>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Senha"
                                                aria-describedby="basic-addon1"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                onBlur={handlePasswordBlur} />
                                        </div>
                                        {passwordError && <span className="error-message">{passwordError}</span>}
                                        <button type="submit" className='btn entrar' onClick={handleLogin}>Login</button>
                                    </form>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
