import React, { useState, useEffect } from 'react';
import Icon from "../../assets/favicon.ico"
import Navbar from "react-bootstrap/Navbar"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav"
import { useNavigate } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import TitleAnim from '../../components/TitleAnim';

// PAGES
import CardProduct from "../../components/CardProduct"
import CardCategory from '../../components/CardCategory'

import "@trendmicro/react-sidenav/dist/react-sidenav.css"
import "font-awesome/css/font-awesome.min.css"
import styled from "styled-components"
import './styles.css';

const Main = styled.main`
  position: relative;
  overflow: auto;
  transition: all 0.15s;
  background-color: #fcfcfd;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
  height: calc(100vh - 56px - 57px);
`
const Head = styled.div`
  position: relative;
  overflow: auto;
  height: 56px;
`
const Bar = styled.div`
  position: relative;
  height: calc(100vh - 56px);
`
// const Separator = styled.div`
//   clear: both;
//   position: relative;
//   margin: 0.8rem;
//   background-color: #f9dcdd;
//   height: 1px;
// `

const SideNavF = styled(SideNav)`
  background: #303443;
`
const Home = () => {

  const handleLogout = () => {
    // Exibir um alerta antes do logout
    const confirmLogout = window.confirm('Tem certeza de que deseja fazer logout?');

    if (confirmLogout) {
        // Remover o token do localStorage
        localStorage.removeItem('token');
        
        // Exibir um alerta de logout bem-sucedido
        //window.alert('Logout realizado com sucesso! Você será redirecionado para o site principal.');

        // Redirecionar para o site principal após 2 segundos
        setTimeout(() => {
            window.location.href = 'https://www.gigabytetelecom.com.br';
        }, 2000);
    }
};

  

  const navigate = useNavigate();
  const [emailFromToken, setEmailFromToken] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Verificar se o token existe no localStorage
    const token = localStorage.getItem('token');

    // Se o token não existir, redirecionar para a página de login
    if (!token) {
      return (
        navigate("/")
      );
    } else {
      try {
        // Decodificar o token
        const decodedToken = jwt.decode(token);

        // Exibir informações do token
        console.log('Token decodificado:', decodedToken);

        // Exemplo de como acessar dados do token e armazenar no estado local
        const emailFromToken = decodedToken?.email;

        setEmailFromToken(emailFromToken);
        setLoading(false);  // Defina loading como falso quando os dados estiverem prontos

        // console.log('Username from token:', emailFromToken);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error.message);
        setLoading(false);  // Certifique-se de definir loading como falso mesmo em caso de erro
      }
    }
  }, [navigate]);


  var sel = window.location.href.split("#/")[1]

  sel = sel === "" || !sel ? "product" : sel

  const [view, setView] = useState(sel)

  const [expanded, setState] = useState(false)



  return (
    <>
      <Head expanded={expanded}>
        <Navbar variant="dark" bg="dark" className="justify-content-between" sticky="top">
          <Image className="px-1" alt="logo" src={Icon} width="28" height="20" />
          <Navbar.Brand className="px-1">Gigabyte Telecom</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="px-2 anim">
              {!loading && emailFromToken !== null && (
                <TitleAnim static texts={[emailFromToken]} />
              )}{' '}              
             
            </Navbar.Text>
          </Navbar.Collapse>
          <Form inline>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-2">Sair</Tooltip>}
            >
              <Button
                variant="outline-success"
                className="mr-sm-2"
                onClick={handleLogout}
                rel="noopener noreferrer"
              >
                <i className="fa fa-fw fa-sign-out" style={{ fontSize: "1.25em" }}></i>
              </Button>
            </OverlayTrigger>

            {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Sair</Tooltip>}>
              <Button
                variant="outline-success"
                className="mr-sm-2 d-none d-md-block"
                onClick={handleLogout}
                rel="noopener noreferrer"
              >
                <i className="fa fa-fw fa-sign-out" style={{ fontSize: "1.25em" }} />
              </Button>
            </OverlayTrigger> */}
          </Form>
        </Navbar>
      </Head>
      <Bar>
        <SideNavF
          expanded={expanded}
          onToggle={(expanded) => {
            setState(expanded)
          }}
          onSelect={(selected) => {
            const to = "#/" + selected
            if (view !== to) {
              setView(selected)
            }
          }}
        >
          <SideNav.Toggle />
          <SideNav.Nav selected={view} defaultSelected={sel}>
            <NavItem eventKey="product">
              <NavIcon>
                <i className="fa fa-fw fa-cutlery" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Cadastrar Produtos</NavText>
            </NavItem>
            <NavItem eventKey="category">
              <NavIcon>
                <i className="fa fa-fw fa-book" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Cadastrar Categoria</NavText>
            </NavItem>
            {/* <Separator />
            <NavItem eventKey="private">
              <NavIcon onClick={handleLogout}>
                <i className="fa fa-fw fa-sign-out" style={{ fontSize: "1.75em" }} />
              </NavIcon>
              <NavText>Logout</NavText>
            </NavItem> */}
          </SideNav.Nav>
        </SideNavF>
        <Main expanded={expanded}>
          {view === "product" && <CardProduct />}
          {view === "category" && <CardCategory />}
          {/* {view === "question" && <Question />}                    */}
        </Main>
        <Navbar variant="light" bg="light" className="justify-content-between border-top" sticky="bottom">
          <Navbar.Brand className="px-1"></Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="px-2"><strong>©</strong> Gigabyte Telecom {new Date().getFullYear()}</Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </Bar>
    </>
  );
}

export default Home;
